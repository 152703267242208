import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PAGE_TITLE_SUFFIX } from '@shared/constants';

const routes: Routes = [
  {
    path: 'activate-account/:uid/:token',
    loadComponent: () =>
      import('./activate-account/activate-account.component').then(
        (m) => m.ActivateAccountComponent
      ),
    title: 'Activate Account' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
    title: 'Login' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      ),
    title: 'Forgot Password' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'password-reset/:uid/:token',
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
    title: 'Password Reset' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./signup/signup.component').then((m) => m.SignUpComponent),
    title: 'Sign Up' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'signup-closed',
    loadComponent: () =>
      import('./signup-closed/signup-closed.component').then(
        (m) => m.SignUpClosedComponent
      ),
    title: 'Sign Up Closed' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    title: 'Page Not Found' + PAGE_TITLE_SUFFIX
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
