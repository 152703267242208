import { AccountRoutingModule } from '@account/account-routing.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards';

const routes: Routes = [
  { path: 'home', redirectTo: '/pm', pathMatch: 'full' },
  {
    path: 'pm',
    loadChildren: () =>
      import('../project-manager/project-manager-routing.module').then(
        (m) => m.ProjectManagerRoutingModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'st',
    loadChildren: () =>
      import('../span-task/span-task-routing.module').then(
        (m) => m.SpanTaskRoutingModule
      ),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [AccountRoutingModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {}
